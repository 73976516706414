<!-- <button (click)="discover()">click to create slider</button>
  
-->
<div *ngIf="imageArr$ | async as imageArr">
  <ngx-wrapper-tiny-slider
    [config]="tinySliderConfig"
    [initManually]="false"
    #tinySlider
  >
    <div class="item" *ngFor="let imageUrl of imageArr">
      <img [src]="imageUrl" />
    </div>
  </ngx-wrapper-tiny-slider>

  <button (click)="prev()">prev</button>
  <button (click)="next()">next</button>
</div>

<hr />

<ngx-wrapper-tiny-slider
  [config]="tinySliderConfig2"
  [initManually]="false"
  #tinySlider2
>
  <div class="item" *ngFor="let imageUrl of imageArr2">
    <img [src]="imageUrl" />
  </div>
</ngx-wrapper-tiny-slider>

<button (click)="pause()">pause</button>
<button (click)="play()">play</button>

<hr />

<ngx-wrapper-tiny-slider
  [config]="tinySliderConfig3"
  [initManually]="true"
  #tinySlider3
>
  <div class="item" *ngFor="let imageUrl of imageArr2">
    <img [src]="imageUrl" />
  </div>
</ngx-wrapper-tiny-slider>
