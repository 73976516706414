<nav class="nav">
  <div class="nav__content">
    <div class="nav__logo" cursor-logo cursor-color="#FFA000">Ngx Simple</div>
    <div class="nav__menu">
      <!-- <a routerLinkActive="active" routerLink="/wrapper-tiny-slider">slider</a> -->
      <a routerLinkActive="active" routerLink="/parallax">parallax</a>
      <a routerLinkActive="active" routerLink="/countdown">countdown</a>
      <a routerLinkActive="active" routerLink="/cursor">cursor</a>
    </div>
  </div>
</nav>

<div class="main">
  <router-outlet></router-outlet>
</div>
