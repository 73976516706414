import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-cursor-component',
  templateUrl: './ngx-cursor.component.html',
  styleUrls: ['./ngx-cursor.component.scss']
})
export class NgxCursorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
