<div>
  <div class="img" cursor-color="#FFF">
    <img src="assets/3.jpg" alt="" />
  </div>
</div>

<div class="listlink">
  <a href="http://google.com">hover simple</a>
  <a href="http://google.com" cursor-opacity="1">hover opacity</a>
  <a href="http://google.com" cursor-color="red">hover change color</a>
  <a href="http://google.com" cursor-opacity="1" cursor-color="red"
    >hover change color + opacity</a
  >
  <a href="http://google.com" cursor-opacity="1" cursor-color="transparent"
    >hover transparent</a
  >
  <a href="http://google.com" cursor-view>hover custom text</a>
  <a href="http://google.com" cursor-stop cursor-color="red"
    >hover custom text + color</a
  >
  <a
    href="http://google.com"
    cursor-color="transparent"
    cursor-opacity="1"
    cursor-border="2px solid red"
    cursor-size="50px"
    >hover custom border and transparent</a
  >
  <a
    href="http://google.com"
    cursor-lorem
    cursor-opacity="1"
    cursor-size="200px"
    >hover lorem</a
  >
</div>

<br />
<br />
<br />
<br />
<br />

<!-- [cursor]="false" -->
<ngx-cursor
  [zindex]="9999"
  [selectors]="['plus', 'view', 'stop', 'read', 'lorem', 'logo']"
  [words]="[
    'more',
    'voir plus',
    'STOP',
    'Lire',
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione laudantium laborum nihil vel libero ipsam ducimus, tenetur at labore quae.',
    'logo'
  ]"
></ngx-cursor>
