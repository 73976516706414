import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-simple-count-cursor1',
  templateUrl: './cursor1.component.html',
  styleUrls: ['./cursor1.component.scss']
})
export class Cursor1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
