import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-simple-count-cursor2',
  templateUrl: './cursor2.component.html',
  styleUrls: ['./cursor2.component.scss']
})
export class Cursor2Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
